<template>
	<div class="p-grid crud-demo" :key="componentKey">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" @click="openNew" />
						<Button label="Delete" icon="pi pi-trash" class="p-button-danger p-mb-2" @click="confirmDeleteSelected" :disabled="!selectedcoupons || !selectedcoupons.length" />
					</template>
                </Toolbar>
				<DataTable ref="dt" :value="coupons" class="p-datatable-responsive-demo" v-model:selection="selectedcoupons" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} coupons" :loading="loading1">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Manage coupons</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<!-- <Column field="id" header="ID" :sortable="true" headerStyle="width: 30%"></Column> -->
					<Column field="name" header="Name" :sortable="true" headerStyle="width: 30%"></Column>
					<Column field="code" header="Code" :sortable="true" headerStyle="width: 30%"></Column>
					<Column field="amount" header="Amount" :sortable="true" headerStyle="width: 30%"></Column>
					<!-- <Column field="is_percentage" header="Type" :sortable="true" headerStyle="width: 30%"></Column> -->

					<Column field="is_percentage" header="Type" :sortable="true" headerStyle="width: 30%">
						<template #body="slotProps">
							<span class="p-column-title">Type</span>
							<span v-if="slotProps.data.is_percentage==1" class='post-badge status'>%</span>
							<span v-else class='post-badge status'>==</span>
						</template>
					</Column>

					<Column field="used" header="Used" :sortable="true" headerStyle="width: 30%">
						<template #body="slotProps">
							<span class="p-column-title">Used</span>
							<span v-if="slotProps.data.used==1" class='post-badge status'>YES</span>
							<span v-else class='post-badge status'>NO</span>
						</template>
					</Column>


					<Column headerStyle="width: 10%">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editCoupon(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteCoupon(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="couponDialog" :style="{width: '800px'}" header="Coupon Details" :modal="true" class="p-fluid">
					<div class="p-formgrid p-grid p-jc-center">
						<div class="p-field p-col-6">
							<label for="name">Name</label>
							<InputText id="name" v-model.trim="coupon.name" required="true" autofocus :class="{'p-invalid': submitted && !coupon.name}" />
							<small class="p-invalid" v-if="submitted && !coupon.name">Name is required.</small>
						</div>
						<div class="p-field p-col-6">
							<label for="code">Code</label>
							<InputText id="code" v-model.trim="coupon.code" required="true" type="number" autofocus :class="{'p-invalid': submitted && !coupon.code}" />
							<small class="p-invalid" v-if="submitted && !coupon.code">Code is required.</small>
						</div>
						<div class="p-field p-col-6">
							<label for="amount">Amount</label>
							<InputText id="amount" v-model.trim="coupon.amount" required="true" autofocus :class="{'p-invalid': submitted && !coupon.amount}" />
							<small class="p-invalid" v-if="submitted && !coupon.amount">Amount is required.</small>
						</div>
						<div class="p-field p-col-6">
							<label for="title">Type</label>
							<SelectButton v-model="typeOption" :options="typeOptions" optionLabel="name" />
						</div>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveCoupon" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteCouponDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="coupon">Are you sure you want to delete <b>{{coupon.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteCouponDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteCoupon" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteCouponsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="coupon">Are you sure you want to delete the selected coupons?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteCouponsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedCoupons" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import CouponService from '../service/CouponService';

export default {
	data() {
		return {
			coupons: [],
			couponDialog: false,
			deleteCouponDialog: false,
			deleteCouponsDialog: false,
			coupon: {},
			selectedcoupons: null,
			filters: {},
			submitted: false,
			typeOptions:[{name: 'Percentage', is_percentage:'1'}, {name: 'Sum', is_percentage:'0'}],
			typeOption:{},
			loading1: true,
			componentKey: 0,
		}
	},
	couponService: null,
	
	created() {
		this.couponService = new CouponService();
	},
	
	mounted() {
		this.onMounted()
	},
	
	methods: {
		onMounted() {
			this.couponService.getCoupons().then(data => {
				if (data.isLogged){
					this.coupons = data.coupon_list
					this.loading1 = false
				} else {
					this.$router.push('Login')
				}
			});
		},
		
		openNew() {
			this.coupon = {};
			this.typeOption = {};
			this.submitted = false;
			
			this.couponDialog = true;
			
		},
		
		
		hideDialog() {
			this.couponDialog = false;
			this.submitted = false;
        },
		
		saveCoupon() {
			this.submitted = true;
			
			this.coupon.is_percentage = this.typeOption.is_percentage
			
			
			if (this.coupon.id) {
				this.couponService.updateCoupon(this.coupon)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {
						this.couponDialog = false;
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
			} else {
				this.couponService.insertCoupon(this.coupon)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {
						this.couponDialog = false;
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
			}
			this.couponDialog = false;
		},
		
		editCoupon(coupon) {
			this.coupon = {...coupon};
			this.typeOption = this.typeOptions.find(item => item.is_percentage == this.coupon.is_percentage)

			this.couponDialog = true;
		},
		
		confirmDeleteCoupon(coupon) {
			this.coupon = coupon;
			this.deleteCouponDialog = true;
		},
		
		deleteCoupon() {
			this.couponService.deleteCoupon(this.coupon)
				.then(response => {
					console.log(response)
					return response
				})
				.then(response => {
					this.deleteCouponDialog = false;
					return response
				})
				.then(response => {
					this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
					return response
				})
				.then(response => {
					this.onMounted()
					return response
				}) 
				.catch(error => {
					console.log(error)
				})
		},
		
		confirmDeleteSelected() {
			this.deleteCouponsDialog = true;
		},
		
		deleteSelectedcoupons() {
			this.coupons = this.coupons.filter(val => !this.selectedcoupons.includes(val));
			this.deleteCouponsDialog = false;
			this.selectedcoupons = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'coupons Deleted', life: 3000});
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.coupon-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.coupon-image-modal {
	width: 100%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin: 1rem 0;
	display: block;
}
.p-dialog .coupon-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.coupon-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-published {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-saved {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
