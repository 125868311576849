import FormData from 'form-data';

import axios from "axios";
axios.defaults.withCredentials = true;

export default class CouponService {

    async getCoupons() {
        return await axios.get('https://advantex.uz/coupon/getCoupons').then(res => res.data);
    }

    updateCoupon(coupon) {
        var data = new FormData()
        data.append('name', coupon.name)
        data.append('code', coupon.code)
        data.append('amount', coupon.amount)
        data.append('is_percentage', coupon.is_percentage)
        data.append('used', coupon.used)
        data.append('id', coupon.id)

        var config = {
            method: 'post',
            url: 'https://advantex.uz/coupon/updateCoupon',
            data: data
        }

        return axios(config)
    }

    insertCoupon(coupon) {
        var data = new FormData()
        data.append('name', coupon.name)
        data.append('code', coupon.code)
        data.append('amount', coupon.amount)
        data.append('is_percentage', coupon.is_percentage)
        data.append('used', false)

        var config = {
            method: 'post',
            url: 'https://advantex.uz/coupon/insertCoupon',
            data: data
        }

        return axios(config)
    }

    deleteCoupon(coupon) {
        var data = JSON.stringify({
            "id": coupon.id
        });

        var config = {
            method: 'delete',
            url: 'https://advantex.uz/coupon/deleteCoupon',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config)
    }
}